.tile-hover img {
  transition: all 0.3s;
}

.tile-hover:hover img {
  transform: scale(1.1);
}

.tile-overlay:after {
  @apply absolute;
  @apply w-full;
  @apply h-full;
  @apply bottom-0;
  @apply bg-gradient-to-t;
  @apply from-black;
  @apply via-transparent;
  @apply to-transparent;
  @apply opacity-60;

  content: '';
}

.tile-watermark-wrapper {
  @apply h-full;
}

.tile-watermark-image {
  @apply opacity-25;
}
